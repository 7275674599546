import React from "react"
import SEO from "../components/seo"
import { FaArrowRight, FaEnvelope } from 'react-icons/fa'

import "../scss/our-stories.scss"

import Img2 from "../assets/img/youpalGroup/ourStories/Img2.png"

const OurStoriesPage = () => {
    return (
        <div>
            <SEO title="Our Stories" keywords={[`youpal`, `youpal group`, `our stories`]} />
            <div className="ourStoriesMain">
                <section className="container">
                    <div className="col">
                        <div className="internalMainHeader bugP">
                            <h2 className="internalNarrowCol">Velit fermentum.</h2>
                        </div>
                        <p className="internalNarrowCol">
                        Stories page
                        </p>
                    </div>
                </section>

                <div className="mainBody">
                    <section className="container internalSection">
                        <div className="row TwoCol">
                            <div className="col">
                                <div className="col sideContent">
                                    <div className="internalSmallSecondaryHeader text-left">ABOUT US</div>
                                    <h3 className="text-left">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Semper posuere rhoncus cras pharetra netus duis.</h3>
                                </div>
                            </div>
                            <div className="col">
                                <div className="col sideContent">
                                    <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tempus arcu, bibendum ut in et. Nunc vel vestibulum laoreet vitae interdum magna enim. Lobortis ornare massa, malesuada ut. Massa nulla semper lorem arcu, pharetra aenean gravida vivamus etiam. In scelerisque mattis lacus nec, aliquam at rhoncus. Adipiscing mi justo et risus pharetra massa tristique. Nunc tristique rhoncus quam euismod pharetra odio.
                                    Id euismod sed augue euismod. Sed sit in quis dui velit vulputate.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="container">
                        <div className="col text-left">
                            <h3>Netus adipiscing eu turpis diam convallis eu.</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Accumsan ac ullamcorper sollicitudin nunc a pretium. Venenatis, turpis at sed aliquam auctor ut. Leo odio at scelerisque leo condimentum vulputate sed. Leo ornare adipiscing venenatis turpis natoque lectus nisl non viverra. Vulputate elementum risus nunc, vel consequat, mi volutpat quam. Egestas vestibulum ipsum, in at in. Vulputate praesent posuere duis mi non eget facilisis mauris lorem. Nullam vitae vulputate sodales.</p>
                            <h2>At malesuada.</h2>
                            <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et eleifend imperdiet habitant curabitur accumsan adipiscing fermentum ac rutrum. Pretium varius neque in eleifend vitae nunc imperdiet viverra. Placerat luctus sem at vel lacus consectetur hendrerit mi blandit. Eget nibh proin amet consequat, quis proin. Amet, lorem vestibulum vestibulum, diam adipiscing ultrices. Arcu scelerisque orci amet arcu risus sed. Est dui pellentesque at eget. Semper vitae urna, pulvinar ultrices. Nibh a venenatis tincidunt semper diam non. Nisl tristique odio consequat, enim molestie. Curabitur aliquam velit arcu sed quam nunc. Integer et amet, varius turpis diam arcu, risus interdum. Quam blandit velit, nunc, ac volutpat interdum ut mattis parturient.
                            Purus felis etiam ornare quis diam fames sed viverra. Gravida cursus malesuada in in scelerisque. Nulla pretium varius interdum vitae. Duis etiam leo lobortis mauris tristique at aliquam elit. Vehicula gravida id rutrum lobortis. Sit cursus etiam erat ligula justo auctor mattis scelerisque. In ac tortor consequat, adipiscing donec tincidunt dolor semper urna.
                            Mauris, urna, duis dictum est. Aliquam eget in eu commodo nascetur. Id mattis tellus sit nulla luctus. Luctus sit sit felis a dolor sit ornare a, quisque. Posuere sed ultrices consequat molestie hendrerit.
                            In turpis risus orci nisl, cras massa. Nec commodo, magna libero, sed vulputate tortor egestas. Volutpat nisl consequat erat nibh habitant eu mattis. Ac massa vulputate elit rutrum a a id tristique. Tempor mattis nulla odio ac. Tellus faucibus egestas volutpat convallis vitae fermentum vulputate quisque convallis. Tincidunt vel at diam velit pharetra quis. Tortor adipiscing suspendisse quis id. Vel, massa est aenean praesent auctor aenean consequat, cursus augue. Eu in tincidunt varius elit feugiat sollicitudin nunc cras eros. Dictum leo maecenas id et lectus tortor, non, consectetur eget. Id lectus et semper gravida tempor.
                            Mauris, quis amet neque metus tortor ultrices. Posuere est et rhoncus, metus molestie. Est pellentesque egestas nulla mi fermentum non sed nisi, at. In mi mauris volutpat malesuada viverra dolor. Sit commodo montes, fames nisi. Non ultricies sed lectus orci commodo. Viverra cras ac tincidunt imperdiet ut sit lacus aliquam. Enim sem cras quis neque. Gravida arcu sagittis scelerisque.
                            </p>
                        </div>
                    </section>

                    <section className="container internalSection">
                        <div className="row TwoCol">
                            <div className="col">
                                <div className="col sideContent">
                                    <h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </h3>
                                    <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Enim, nibh enim sed tortor nulla diam lorem. Vestibulum egestas nibh eleifend volutpat accumsan.
                                    </p>
                                </div>
                            </div>
                            <div className="col">
                                <img className="sideImg" src={Img2} />
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )
}

export default OurStoriesPage
